import React from "react";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { translate } from "@clearabee/i18n";
import { withWebLayout } from "lib/hoc/withWebLayout";

const NotFoundPage = (): React.ReactElement => {
  return (
    <ErrorPage
      errorTitle={translate("hwa.errors.pageNotFound")}
      errorMessage={translate("hwa.errors.pageNotFoundMessage")}
      linkText={translate("hwa.errors.homepage")}
    />
  );
};

export default withWebLayout(NotFoundPage);
